import {Col, Row} from "react-bootstrap";
import { useEffect, useState } from "react";
import { Bounce, toast } from "react-toastify";
import api from "../_services/api";
import { CardProduto } from "./CardProduto";
import { OffcanvasExample } from "./Navbar";



function ListaProdutos(){

    const [Produtos, setProdutos] = useState([]);

    useEffect(() => {
        buscarProdutos();
      }, []);
    
      async function buscarProdutos() {
        try {
          const response = await api.get("/productlistbyremark/home");
          setProdutos(response.data);
        } catch (error) {
          mensagemDeErro("Erro para buscar os produtos.");
        }
    }





    function mensagemDeErro (mensagem = "Ta louco pooooo"){
        toast.error(mensagem, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
    }

    return(

        <>

        <OffcanvasExample />
        <Row style= {{
          marginTop:'10VH',
          // maxWidth: '95vw',
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
        }} >
        {Produtos.map((produto, index) => {
          return (
            <Col 
              // style={{
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',
              // }} 
              className="m-2" key={index}>
              <CardProduto
                idProduto={produto.id}
                nome={produto.title}
                preco={produto.price}
                linkImagem={produto.image}
                descricao={produto.category}
                promotion={produto.special_price}
              />
            </Col>
          );
        })}
        </Row>
        </>

    );

}

export {ListaProdutos};