import React from "react";

// const Contrato = () => {
//   const documentURL =
//     "https://docs.google.com/document/d/1Jz17ehaHHiyjJezx0bQnpEvNrjrDsMD4/edit?usp=sharing&ouid=116806013124704168732&rtpof=true&sd=true"; // Replace DOCUMENT_ID with your actual document ID

//   return (
//     <div>
//       <iframe
//         src={documentURL}
//         width="100%"
//         height="600px"
//         title="Google Document Viewer"
//         style={{ border: "none" }}
//       />
//     </div>
//   );
// };

// export default Contrato;

function Contrato() {
  const documentURL =
    "https://docs.google.com/document/d/1Jz17ehaHHiyjJezx0bQnpEvNrjrDsMD4/edit?usp=sharing&ouid=116806013124704168732&rtpof=true&sd=true"; // Replace DOCUMENT_ID with your actual document ID

  return (
    <>
      <div>
        <iframe
          src={documentURL}
          width="100%"
          height="600px"
          title="Google Document Viewer"
          style={{ border: "none" }}
        />
      </div>
    </>
  );
}

export { Contrato };
