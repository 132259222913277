// import { Form, InputGroup } from "react-bootstrap";
import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
// import "../assets/css/login.css";


function Loging (){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
  
    let navigate = useNavigate();
  
    function onchangeEmail(event) {
      setEmail(event.target.value);
    }
    function onchangeSenha(event) {
      setSenha(event.target.value);
    }
  
    function mensagemDeErro(mensagem = "Mensagem de erro") {
      toast.error(mensagem, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  
    function mensagemDeSucesso(mensagem = "") {
      toast.success(mensagem, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    
    async function submitForm(event) {
      event.preventDefault();
      setValidated(true);
  
      const form = new FormData();
      form.append("email", email);
      form.append("password", senha);
      const options = {
        method: "POST",
        mode: "cors",
        body: form,
      };
  
      try {
        const response = await fetch(
          "https://webapp353621.ip-45-79-142-173.cloudezapp.io/api/login",
          options
        );
        mensagemDeSucesso("Logado com sucesso");
        const data = await response.json();
        const token = data.token
        const nomeUsuario = data.user.name
        const emailUsario = data.user.email
  
        localStorage.setItem('token', token)
        localStorage.setItem('nomeUsuario', nomeUsuario)
        localStorage.setItem('emailUsario', emailUsario)
  
        navigate("/");
      } catch (error) {
        mensagemDeErro("Não foi possivel realizar login");
      }
    }


    return (
        <>
        <Form noValidate validated={validated} onSubmit={submitForm}>
        <Form.Group controlId="validationEmail">
            <InputGroup hasValidation size="sm" className="mb-3">
              <InputGroup.Text id="inputEmail" >@</InputGroup.Text>
              <Form.Control
                type="text"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="E-mail"
                aria-label="Small"
                required
                onChange={onchangeEmail}
            />
            <Form.Control.Feedback type="invalid">
                          Adicionar um email valido.
            </Form.Control.Feedback>
            </InputGroup>
        </Form.Group >

        <Form.Group controlId="validationCustomUsername">
            <Form.Label>Senha</Form.Label>
            <InputGroup hasValidation size="sm" className="mb-3">
                <InputGroup.Text id="inputSenha">****</InputGroup.Text>
                <Form.Control
                    type="password"
                    placeholder="Senha"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    onChange={onchangeSenha}
                />
                <Form.Control.Feedback type="invalid">
                          Senha é obrigatorio.
                </Form.Control.Feedback>
            </InputGroup>
            </Form.Group>
            <Button variant="info" href="/Cadastro">
                Criar conta
              </Button>
              <Button variant="primary"  type="submit" >
                Logar
              </Button>
              </Form>
        </>
    );
}

export {Loging}