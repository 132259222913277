import { OffcanvasExample } from "../componentes/Navbar";



function Cadastro(){

    return(
<>
<OffcanvasExample></OffcanvasExample>


</>
    );
}

export {Cadastro}