import { Button, Card } from "react-bootstrap";
import { Bounce, toast } from "react-toastify";
import { CarrinhoContext } from "../Contexet/CarrinhoContext"


function CardProduto({ idProduto, nome, preco, descricao, linkImagem, promotion }) {
  //tentei mudar de diferente de para menor que e nao funcionou. porque?
  function applyPromotion(mainValue, promotionValue) {
    if (promotionValue !== mainValue) {
      return promotionValue; // Apply the promotion value
    } else {
      return null; // If the promotion price is the same as the main price, return null
    }
  }

  const finalPrice = applyPromotion(preco, promotion);



  function addedToCart(mensagem="added to cart"){
    toast.success(`${nome} - adicionado para carrinho`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      });

  }

  
  return (
    <Card>
      <Card.Img variant="top" src={linkImagem} />
      <Card.Body>
        <Card.Title 
        style={{ 
          maxWidth: '18vw',
          fontSize: '1.5vw',
          fontSizeAdjust: 'auto'      

        }}>
          {nome} <br/> R$: {preco}
          
        </Card.Title>
        <Card.Text >{descricao}</Card.Text>
        <Card.Text>{finalPrice && <p 
          style={{ 
            backgroundColor: 'red', 
            color: 'white', 
            width: '10rem', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
            }}
          >Promoção: ${finalPrice}</p>}
          </Card.Text>
          <Button
          variant="primary"
          onClick={() => addedToCart(idProduto, nome, preco, descricao, linkImagem) }
        >
          ADICIONAR
        </Button>
      </Card.Body>
    </Card>
  );
}
export {CardProduto};