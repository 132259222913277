import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import{ ShoppingCart } from 'phosphor-react';


//
import { useState } from 'react';
import { InputGroup, Modal } from 'react-bootstrap';
import { Loging } from './Loging';
import { GerenciarLogin } from './GerenciadorDeLogin';
//

function OffcanvasExample() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className=" mb-5 navBar" fixed="top">
          <Container fluid>
            <Navbar.Brand className='rent_a_goat'>Rent a Goat</Navbar.Brand>

          <div  >
            <div>
          <GerenciarLogin/>
          </div>
          <Button variant="outline-dark" href="/Carrinho"><ShoppingCart size={30} /></Button>
          <Button style={{marginRight:'2vw', marginLeft:'2vw'}}  variant="primary" onClick={handleShow}>
              Loging
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Loga para ficar legal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loging/>
            </Modal.Body>
            <Modal.Footer>
            
            </Modal.Footer>
          </Modal>
    


          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu Principal
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/Home">Home</Nav.Link>
                  <Nav.Link href="/Carrinho">Carinho </Nav.Link>
                  <Nav.Link href="/Produtos">Produtos </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export {OffcanvasExample};