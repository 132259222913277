import { Container } from "react-bootstrap";
import { NoTransitionExample } from "../componentes/Carrossel";
import { ListaProdutos } from "../componentes/ListaProdutos";
import { OffcanvasExample } from "../componentes/Navbar";
import React from "react";

function Home() {
  const documentURL =
    "https://docs.google.com/document/d/1Jz17ehaHHiyjJezx0bQnpEvNrjrDsMD4/edit?usp=sharing&ouid=116806013124704168732&rtpof=true&sd=true"; // Replace DOCUMENT_ID with your actual document ID

  return (
    <div>
      <iframe
        src={documentURL}
        width="100%"
        height="600px"
        title="Google Document Viewer"
        style={{ border: "none" }}
      />
    </div>

    // <>
    // <OffcanvasExample/>
    // <Container>
    // <NoTransitionExample/>

    // <ListaProdutos/>
    // </Container>
    // </>
  );
}

export { Home };
