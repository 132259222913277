import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ListaProdutos } from "../componentes/ListaProdutos";
import { Produtos } from "../pages/Produtos";
import { Carrinho } from "../pages/Carrinho";
import { Cadastro } from "../pages/Cadastro";
import { Home } from "../pages/Home";
import { Contrato } from "../pages/Contrato_imobiliaria";
export function Routeapp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Produtos" element={<Produtos />}></Route>
        <Route path="/Carrinho" element={<Carrinho />}></Route>
        <Route path="/Cadastro" element={<Cadastro />}></Route>
        <Route path="/Home" element={<Home />}></Route>
        <Route path="/Contrato" element={<Contrato />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
