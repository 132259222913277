import { CardSubtitle, Container } from "react-bootstrap";
import { Cards } from "../componentes/Card";
import { OffcanvasExample } from "../componentes/Navbar";
import { ListaProdutos } from "../componentes/ListaProdutos";




function Produtos(){

    return (
        <>
        <OffcanvasExample/>
        <Container>
        <ListaProdutos/>
        </Container>
        </>
    );

}

export {Produtos};