import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";

import { Componente2 } from './componentes/Componente2.jsx';
import { Novocomponente } from './componentes/Novocomponente.jsx';

import { Routeapp } from './route/index.jsx';
import { ToastContainer } from 'react-toastify';
import { ShoppingCart } from 'phosphor-react';
import CarrinhoContextProvider from './Contexet/CarrinhoContext.js';


function App() {
  return (
  <>
      <CarrinhoContextProvider>
      <ToastContainer/>
    
      <Routeapp/>
      </CarrinhoContextProvider>
    </>
  );
}
export default App;
